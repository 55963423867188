import React from 'react'
import { graphql } from 'gatsby'
import Work from '../components/work'
// import AllProjectsQuery from '../hooks/all-projects-query'

export const ALL_PROJECTS_QUERY = graphql`
  query ALL_PROJECTS_QUERY {
    featuredProjects: allProjectsJson(filter: { featured: { eq: true } }) {
      nodes {
        slug
        featured
        icon {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        iconName
        name
        clientType
        internetStage
        contractAddress
        nftMarketplace
        productType
        description
        stack
        url
      }
    }
    moreProjects: allProjectsJson(filter: { caseStudy: { eq: false } }) {
      nodes {
        slug
        featured
        icon {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        iconName
        name
        clientType
        internetStage
        contractAddress
        nftMarketplace
        productType
        description
        stack
        url
      }
    }
  }
`

const WorkPageTemplate = ({ data }) => {
  const featuredProjects = data.featuredProjects.nodes

  const moreProjects = data.moreProjects.nodes

  return <Work featuredProjects={featuredProjects} moreProjects={moreProjects} />
}

export default WorkPageTemplate
