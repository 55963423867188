/** @jsx jsx */
import { jsx } from 'theme-ui'
import ProjectCard from './projectCard'
import { Fragment } from 'react'

const FeaturedProjects = ({ featuredProjects }) => {
  return (
    <Fragment>
      {featuredProjects.map(({ slug, icon, iconName, name, clientType, internetStage = 'web2', contractAddress = 'none', nftMarketplace = 'none', productType, description, stack }, i) => (
        <ProjectCard
          key={i}
          slug={slug}
          icon={icon.childImageSharp.fluid}
          iconName={iconName}
          name={name}
          clientType={clientType}
          internetStage={internetStage}
          contractAddress={contractAddress}
          nftMarketplace={nftMarketplace}
          productType={productType}
          description={description}
          stack={stack}
          label="Read case study"
        />
      ))}
    </Fragment>
  )
}

export default FeaturedProjects
