/** @jsx jsx */
import { jsx, Box, Styled, Flex } from 'theme-ui'
import { Fragment } from 'react'
import { animated, useSpring } from 'react-spring'
import Container from '../components/container'
import Wave from './wave'

const AnimatedBox = animated(Box)

const WorkHero = (props) => {
  const headingFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 200,
  })
  const subtitleFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 350,
  })

  return (
    <Fragment>
      <Box
        sx={{
          variant: 'layout.sectionContainer',
          bg: 'inherit',
          width: '100vw',
          position: 'relative',
          ml: '-50vw',
          left: '50%',
          overflow: 'hidden',
        }}
      >
        <Wave wave="workCaseStudies" transformFrom="top" waveHeight="auto" />
        <Container>
          <Flex sx={{ variant: 'layout.sectionHeroContainerDefault' }}>
            <AnimatedBox
              style={headingFade}
              sx={{
                // textAlign: 'center',
                // mt: [4, 5, 6],
                // position: 'relative',
                variant: 'layout.headingContainer',
              }}
            >
              <Styled.h1 sx={{ variant: 'text.heading', zIndex: '90' }}>Our work</Styled.h1>
            </AnimatedBox>
            <AnimatedBox
              style={subtitleFade}
              sx={{
                // px: [4, 5, 6],
                // textAlign: 'center',
                variant: 'layout.subHeadingContainer',
              }}
            >
              <Styled.h4 sx={{ variant: 'text.subheading' }}>Our clients are unique, and so are their products.</Styled.h4>
            </AnimatedBox>
          </Flex>
        </Container>
      </Box>
    </Fragment>
  )
}

export default WorkHero
