/** @jsx jsx */
import { jsx } from 'theme-ui'
import ProjectCardCompact from './projectCardCompact'
import { Fragment } from 'react'

const MoreProjects = ({ moreProjects, cardWidth, imgWidth }) => {
  return (
    <Fragment>
      {moreProjects.map(({ slug, icon, iconName, name, clientType, productType, internetStage = 'web2', contractAddress = 'none', nftMarketplace = 'none', description, stack, url }, i) => (
        <ProjectCardCompact
          key={i}
          slug={slug}
          icon={icon.childImageSharp.fluid}
          iconName={iconName}
          name={name}
          clientType={clientType}
          productType={productType}
          internetStage={internetStage}
          contractAddress={contractAddress}
          nftMarketplace={nftMarketplace}
          description={description}
          stack={stack}
          label="Visit site"
          cardWidth={cardWidth}
          imgWidth={imgWidth}
          url={url}
        />
      ))}
    </Fragment>
  )
}

export default MoreProjects
