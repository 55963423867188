/** @jsx jsx */
import { jsx, Flex } from 'theme-ui'
import Layout from './layout'
import SEO from './SEO/seo'
import { useLocation } from '@reach/router'
import WorkHero from './workHero'
import WorkCaseStudies from './workCaseStudies'
import WorkMoreProjects from './workMoreProjects'
import ContactSection from './contactSection'
import { Fragment } from 'react'

const Work = ({ featuredProjects, moreProjects }) => {
  const { pathname } = useLocation()
  return (
    <Fragment>
      <Layout location={pathname}>
        <SEO title="Work" description="Modern web apps and smart contract blockchain development. We work with React.js, Next.js, Gatsby.js, and Solidity." />
        <Flex
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <WorkHero />
          <WorkCaseStudies featuredProjects={featuredProjects} />
          <WorkMoreProjects moreProjects={moreProjects} />
          <ContactSection variant="contact" heading="Let's discuss your project" linkToFirstButton="/contact/" />
        </Flex>
      </Layout>
    </Fragment>
  )
}

export default Work
