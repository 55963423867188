/** @jsx jsx */
import { jsx, Box, Styled, Flex } from 'theme-ui'
import { Fragment } from 'react'
import { animated, useSpring } from 'react-spring'
import FeaturedProjects from './featuredProjects'
import Blob from './blob'

const AnimatedBox = animated(Box)
const AnimatedFlex = animated(Flex)

const WorkCaseStudies = ({ featuredProjects }) => {
  const subtitleFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 350,
  })
  const projectFade = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 450,
  })

  return (
    <Fragment>
      <Box
        sx={{
          variant: 'layout.sectionContainer',
          bg: 'inherit',
          width: '100vw',
          position: 'relative',
          ml: '-50vw',
          left: '50%',
          overflow: 'hidden',
        }}
      >
        <Blob blob="workCaseStudies" positionTop="0%" positionLeft="5%" />
        <AnimatedBox
          style={subtitleFade}
          sx={{
            my: [3, 4, 5],
            // mx: [1, 5, 6],
            px: [4, 5, 6],
            textAlign: 'center',
          }}
        >
          <Flex sx={{ my: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['100px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
            <Box sx={{ mx: 2, textAlign: 'center' }}>
              <Styled.p sx={{ fontSize: 3, variant: 'text.caps', color: 'headingSecondary' }}>Case Studies</Styled.p>
            </Box>
            <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['100px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
          </Flex>
        </AnimatedBox>
        {/* <AnimatedBox style={projectFade}> */}
        {/* <Grid sx={{ my: [0, 0, 0], mx: [1, 5, 6] }} gap={1} columns={[`auto`, `auto`, `auto auto`]} variant="cards"> */}
        <AnimatedFlex style={projectFade} sx={{ justifyContent: 'center', flexWrap: 'wrap' }}>
          <FeaturedProjects featuredProjects={featuredProjects} />
        </AnimatedFlex>
        {/* </Grid> */}
        {/* </AnimatedBox> */}
      </Box>
    </Fragment>
  )
}

export default WorkCaseStudies
