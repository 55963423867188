/** @jsx jsx */
import { jsx, Box, Styled, Card, Badge, useThemeUI, Flex } from 'theme-ui'
import { Fragment } from 'react'
import { useToggle } from '../hooks'
import { animated, useSpring } from 'react-spring'
import { WorkLogoAnimation } from './workLogoAnimation'
import ExternalLinkIcon from '../assets/externalLink'
import { ButtonAnimated } from './animation'

const AnimatedCard = animated(Card)

const ProjectCardCompact = ({ slug, icon, iconName, name, clientType, productType, description, stack, url, label, cardWidth = '480px', imgWidth = '80px' }) => {
  const { isToggled: hovered, toggle: setHover } = useToggle(false)

  const context = useThemeUI()
  const { theme } = context

  const cardColorFrom = theme.colors.iconFrom
  const cardColorTo = theme.colors.card

  const cardAnimation = useSpring({
    from: hovered ? { transform: 'translate(0px, 0px)' } : { transform: 'translate(0px, 0px)' },
    to: hovered ? { transform: `translate(0px, -10px)`, background: cardColorTo } : { transform: 'translate(0px, 0px)', background: cardColorFrom },
    config: hovered ? { duration: 150 } : { duration: 150 },
  })

  return (
    <Fragment>
      <AnimatedCard
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        style={cardAnimation}
        variant="primary"
        sx={{ maxWidth: cardWidth, m: [3, 3, 3], p: [2, 2, 2], boxShadow: (theme) => `0 0px 3px 0 ${theme.colors.muted}, 0 1px 2px 0 ${theme.colors.mutedSecondary}`, position: 'relative' }}
      >
        <ExternalLinkIcon url={url} />

        <Box my={3} mx="auto" sx={{ maxWidth: imgWidth }}>
          <WorkLogoAnimation iconName={iconName} icon={icon} />
        </Box>
        <Box py={1} px={3} sx={{ textAlign: 'center' }}>
          <Styled.h4>{name}</Styled.h4>
        </Box>
        <Box py={1} px={3} sx={{ textAlign: 'center' }}>
          <em>
            <Styled.h5 sx={{ variant: 'text.projectTypeTitle' }}>{productType}</Styled.h5>
          </em>
        </Box>
        <Box py={1} px={3}>
          <Styled.p>{description}</Styled.p>
        </Box>
        <Box py={1} px={3}>
          {stack.map((item, i) => (
            <Badge key={i} variant="outline">
              {item}
            </Badge>
          ))}
        </Box>
        <Flex
          sx={{
            // pt: [4, 2, 2],
            px: [1, 1, 1],
          }}
        >
          <ButtonAnimated variant="compactFull" scaleActive="1.1" label={label} linkTo={url} />
        </Flex>
      </AnimatedCard>
    </Fragment>
  )
}

export default ProjectCardCompact
