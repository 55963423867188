/** @jsx jsx */
import { jsx, useThemeUI, Flex } from 'theme-ui'
import { useState, Fragment } from 'react'
import { animated, useSpring } from 'react-spring'
import { navigate } from 'gatsby'

function ExternalLink({ width = '100%', height = '100%', caseStudy = false, url }) {
  const [active, setActive] = useState({ hovered: false, duration: 500 })

  const context = useThemeUI()
  const { theme } = context

  const fromColorFill = caseStudy ? theme.colors.accent : theme.colors.externalLinkFrom
  const toColorFill = theme.colors.externalLinkTo

  const props = useSpring({
    from: active.hovered ? { fill: fromColorFill } : { fill: fromColorFill },
    to: active.hovered ? { fill: toColorFill } : { fill: fromColorFill },
    config: active.hovered ? { duration: 300 } : { duration: active.duration },
  })

  const handleOnClick = () => {
    navigate(url)
  }

  return (
    <Fragment>
      {caseStudy ? (
        <Flex>
          <animated.svg
            // style={props}
            sx={{ cursor: 'pointer' }}
            onMouseEnter={() => {
              setActive({ hovered: true, duration: 500 })
            }}
            onMouseLeave={() => {
              setActive({ hovered: false, duration: 500 })
            }}
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            x="0"
            y="0"
            viewBox="0 0 32 32"
          >
            <animated.g style={props}>
              <path d="M21 17a1 1 0 012 0v6a3 3 0 01-3 3H9a3 3 0 01-3-3V12a3 3 0 013-3h6a1 1 0 010 2H9a1 1 0 00-1 1v11a1 1 0 001 1h11a1 1 0 001-1v-6zM24 8h-5a1 1 0 010-2h6a1 1 0 011 1v6a1 1 0 01-2 0V8z"></path>
              <path d="M14.707 18.707a1 1 0 01-1.414-1.414l11-11a1 1 0 011.414 1.414l-11 11z"></path>
            </animated.g>
          </animated.svg>
        </Flex>
      ) : (
        <button aria-label={`Link to ${url}`} onClick={handleOnClick} sx={{ position: 'absolute', top: '6px', right: '10px', p: '0px', width: '32px', height: '32px', border: 'none', bg: 'inherit' }}>
          <animated.svg
            // style={props}
            sx={{ cursor: 'pointer' }}
            onMouseEnter={() => {
              setActive({ hovered: true, duration: 500 })
            }}
            onMouseLeave={() => {
              setActive({ hovered: false, duration: 500 })
            }}
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            x="0"
            y="0"
            viewBox="0 0 32 32"
          >
            <animated.g style={props}>
              <path d="M21 17a1 1 0 012 0v6a3 3 0 01-3 3H9a3 3 0 01-3-3V12a3 3 0 013-3h6a1 1 0 010 2H9a1 1 0 00-1 1v11a1 1 0 001 1h11a1 1 0 001-1v-6zM24 8h-5a1 1 0 010-2h6a1 1 0 011 1v6a1 1 0 01-2 0V8z"></path>
              <path d="M14.707 18.707a1 1 0 01-1.414-1.414l11-11a1 1 0 011.414 1.414l-11 11z"></path>
            </animated.g>
          </animated.svg>
        </button>
      )}
    </Fragment>
  )
}

export default ExternalLink
