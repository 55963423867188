/** @jsx jsx */
import { jsx, Box, Styled, Flex, Card, Badge, useThemeUI } from 'theme-ui'
import { Fragment } from 'react'
import { useToggle } from '../hooks'
import { animated, useSpring } from 'react-spring'
import { WorkLogoAnimation } from './workLogoAnimation'
import { ButtonAnimated } from './animation'

const AnimatedCard = animated(Card)

const ProjectCard = ({ slug, icon, iconName, name, clientType, internetStage, productType, description, stack, label, cardWidth = '380px', imgWidth = '110px' }) => {
  const { isToggled: hovered, toggle: setHover } = useToggle(false)

  const context = useThemeUI()
  const { theme } = context

  const cardColorFrom = theme.colors.iconFrom
  const cardColorTo = theme.colors.card

  const cardAnimation = useSpring({
    from: hovered ? { transform: 'translate(0px, 0px)' } : { transform: 'translate(0px, 0px)' },
    to: hovered ? { transform: `translate(0px, -10px)`, background: cardColorTo } : { transform: 'translate(0px, 0px)', background: cardColorFrom },
    config: hovered ? { duration: 150 } : { duration: 150 },
  })

  return (
    <Fragment>
      <AnimatedCard onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} style={cardAnimation} variant="primary" sx={{ width: cardWidth, m: [3, 3, 3], p: [2, 2, 2], boxShadow: (theme) => `0 0px 3px 0 ${theme.colors.muted}, 0 1px 2px 0 ${theme.colors.mutedSecondary}` }}>
        <Box my={3} mx="auto" sx={{ maxWidth: imgWidth, height: '100px' }}>
          <WorkLogoAnimation iconName={iconName} icon={icon} />
        </Box>
        <Box py={1} px={3} sx={{ textAlign: 'center' }}>
          <Styled.h5 sx={{ fontSize: [3, 3, 4] }}>{name}</Styled.h5>
        </Box>
        <Box pt={1} pb={3} px={3} sx={{ textAlign: 'center' }}>
          {/* <Styled.h5 sx={{ variant: 'text.projectTypeTitle' }}>{productType}</Styled.h5> */}

          <em>
            <Styled.h5 sx={{ variant: hovered ? 'text.projectTypeTitleHovered' : 'text.projectTypeTitle', fontSize: [3, 3, 4] }}>{productType}</Styled.h5>
          </em>
        </Box>
        <Box py={1} px={3} sx={{ height: ['346px', '270px', '270px', '270px'], overflow: 'hidden' }}>
          <Styled.p>{description}</Styled.p>
        </Box>
        <Box mt={(3, 3, 3)} mb={(3, 1, 1)} px={3} sx={{ height: ['120px', '100px', '100px', '100px'], overflow: 'hidden' }}>
          {stack.map((item, i) => (
            <Badge key={i} variant="outline">
              {item}
            </Badge>
          ))}
        </Box>

        <Flex
          sx={{
            // pt: [4, 2, 2],
            px: [1, 1, 1],
          }}
        >
          <ButtonAnimated variant="compactFull" scaleActive="1.1" label={label} linkTo={`/work/${slug}/`} />
        </Flex>
        {internetStage === 'web3' && (
          <Box sx={{ position: 'absolute', top: '0px', mt: 2, px: 2, border: `1px solid ${theme.colors.web3}`, borderRadius: '4px', backgroundColor: `${theme.colors.web3}` }}>
            <Styled.p sx={{ color: `${theme.colors.projectCopy}` }}>Web3</Styled.p>
          </Box>
        )}
      </AnimatedCard>
    </Fragment>
  )
}

export default ProjectCard
