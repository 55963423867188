/** @jsx jsx */
import { jsx, Box, Styled, Grid, Flex } from 'theme-ui'
import { Fragment } from 'react'
import { Waypoint } from 'react-waypoint'
import { animated, useSpring } from 'react-spring'
import { useToggle } from '../hooks'
import MoreProjects from './moreProjects'
import Blob from './blob'

const AnimatedBox = animated(Box)

const WorkMoreProjects = ({ moreProjects }) => {
  const { isToggled: active, toggle: setActive } = useToggle(false)

  const projectFade = useSpring({
    opacity: active ? 1 : 0,
    delay: active ? 100 : 0,
  })

  return (
    <Fragment>
      <Box
        sx={{
          variant: 'layout.sectionContainer',
          bg: 'inherit',
          width: '100vw',
          position: 'relative',
          ml: '-50vw',
          left: '50%',
          overflow: 'hidden',
        }}
      >
        <Blob blob="workMoreProjects" positionTop="0%" positionLeft="55%" />
        <Waypoint
          bottomOffset="25%"
          onEnter={() => {
            if (!active) setActive(true)
          }}
        />
        <AnimatedBox
          style={projectFade}
          sx={{
            my: [3, 4, 5],
          }}
        >
          <Flex sx={{ my: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['100px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
            <Box sx={{ mx: 2, textAlign: 'center' }}>
              <Styled.p sx={{ fontSize: 3, variant: 'text.caps', color: 'headingSecondary' }}>More Client Projects</Styled.p>
            </Box>
            <Box sx={{ mx: 0, bg: 'headingSecondary', width: ['100px', '130px', '130px'], height: '2px', boxShadow: (theme) => `0 0px 0px 0 ${theme.colors.headingSecondary}`, borderRadius: '2px' }} />
          </Flex>
        </AnimatedBox>
        <AnimatedBox style={projectFade}>
          <Grid sx={{ my: [0, 0, 0], mx: [1, 1, 1] }} gap={2} columns={[`auto`, `auto auto`, `auto auto auto`]} variant="cards">
            <MoreProjects moreProjects={moreProjects} cardWidth="480px" imgWidth="80px" sx={{ border: '1px solid red' }} />
          </Grid>
        </AnimatedBox>
      </Box>
    </Fragment>
  )
}

export default WorkMoreProjects
