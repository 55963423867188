/** @jsx jsx */
import { jsx, Box } from 'theme-ui'
import { Fragment } from 'react'
import { animated } from 'react-spring'
import Img from 'gatsby-image'

const AnimatedBox = animated(Box)

export const WorkLogoAnimation = ({ icon, iconName }) => {
  return (
    <Fragment>
      <AnimatedBox>
        <Img
          fluid={icon}
          title={iconName}
          alt={iconName}
          style={{
            height: '100px',
          }}
          imgStyle={{
            objectFit: 'contain',
            padding: '0.5rem',
          }}
        />
      </AnimatedBox>
    </Fragment>
  )
}
